.server-table-container__top {
    position: relative;
    z-index: 1;
}

.server-table-container__top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
}

.server-table-container__item-count {
    display: inline-block;
    margin: 5px 0;
}
