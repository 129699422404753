
#home-page {
    .-home-cta {
        background-color: #D9D9D9;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 96px;
        height: 96px;

        padding: 16px;
        hyphens: auto;

        color: black;

        &.-em {
            background-color: #c9d5de;
        }
        &:hover {
            color: white;
            background-color: #366789;
            text-decoration: none;
        }
    }

    .o-row {
        display: flex;
        flex-direction: row;
    }

    h4 {
        font-size: 18px;
    }
}
