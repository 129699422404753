
/**
 * Helps to define the scale to keep z-index consistent
 * @param {string} $value
 */
@mixin z-index($value) {
    @if $value == "modal" {
        z-index: 9000;
    }
    @else if $value == "modal-content" {
        z-index: 9001;
    }
    @else {
        @warn("No z-index for '#{$value}'");
    }
}