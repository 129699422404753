.template-sales-summary {

    &.is-loading {
        tr td {
            background: linear-gradient(120deg,
                        #ffffff 30%,
                        #f2f2f2 38%,
                        #f2f2f2 40%,
                        #ffffff 48%);
                background-size: 300%;
                background-position: 100% 0;
                animation: anim 1.5s infinite;
            
            color: transparent;
            text-shadow: 0 0 8px black;

            &:first-child {
                background: none;
                color: inherit;
                text-shadow: none;
            }
        }
        tr:last-child td {
            background: linear-gradient(120deg,
                        #f5f5f5 30%,
                        #e4e4e4 38%,
                        #e4e4e4 40%,
                        #f5f5f5 48%);
                background-size: 300%;
                background-position: 100% 0;
                animation: anim 1.5s infinite;
                
            // text-shadow: 0 0 32px white;

            &:first-child {
                    background: #f5f5f5;
                    color: inherit;
                }
        }
    }
    @keyframes anim {
        to {
            background-position: -100% 0;
        }
    }
    
    table {

        border-left: 1px solid $btn-primary-bg;
        border-right: 1px solid $btn-primary-bg;
        max-width: 950px;

        .-Total {
            font-weight: 600;
            font-size: 1.5em;
            background-color: #f5f5f5;
        }

        .-state_name {
            white-space: nowrap;
        }
        
        &.sales-summary {
            width: 70%;

            // min-width: 600px;
            margin: 20px auto 60px;
        }

        td, th {
            padding: 8px 12px;
            border-left: 1px solid white;
            min-width: 125px;
            min-height: 55px;
            width: 20%;

            &.-average {
                // border-left: 1px dotted rgb(111, 111, 111)
                border-left: 0;
            }

            &:first-child {
                border-left: none;
            }

        }
        // tr {
            
            
        // }
        th {
            background-color: $btn-primary-bg;
            color: white;
            text-transform: uppercase;
            text-align: center;
        }

        td {
            border-left: 1px solid grey;
            border-bottom: 1px solid lightgray;
            &.-average {
                border-left: 1px dotted lightgray;
            }
            &:first-child {
                border-left: none;
                text-transform: uppercase;
            }
        }
        tr:last-child td {
            border-bottom: 1px solid $btn-primary-bg;
        }

        tr.-carstate-1000,
        tr.-carstate-1001 {
            td{
                border-bottom-color: black;
                background-color: rgb(239, 239, 239);
                font-weight: 600;
            }
        }
        // tr.-carstate-0 td,
        // tr.-carstate-2 td{
        tr.-carstate-7 td {
                border-bottom-color: black;
        }

        tr.-carstate-1001 td {
            font-size: 1.2rem;
        }
    }


}