
.browser-alert {
    position: absolute;
    @include z-index('modal');
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize(#000, 0.5);
    display: none;

    &.is-shown {
        display: block;
    }
}

.browser-alert__content {
    position: absolute;
    @include z-index('modal-content');
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
}

.browser-alert__title {
    background-color: #393939;
    color: #ffffff;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
    border-radius: 4px 4px 0px 0px;
}

.browser-alert__body {
    background-color: white;
    border-radius: 0px 0px 4px 4px;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
}

.browser-alert__btn {
    width: 64px;
    height: 64px;
    transition: transform 0.2s;
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
}

.browser-alert__title__closebtn {
    float: right;
    color: #fff;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;

    &:hover {
        color: #fff;
        text-decoration: none;
        opacity: 1;
    }
}