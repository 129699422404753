$font-family-sans-serif: "Trade Gothic", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-h1: 2rem;
$font-size-h2: 1.85rem;

$btn-primary-color: #ffffff;
$btn-primary-bg: #393939;

$brand-red-color: #b43336;

$danger-color: #d9534f;

$enable-print-styles: false;
