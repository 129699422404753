html {
    font-size: 13px;
}

body {
    padding: 7rem 0 2rem 0;

    @media (max-width: 47.9em) {
        padding: 12rem 0 2rem 0;
    }
}


nav.navbar {
    padding: 15px 0 15px 0;
    background: url("/static/img/dusablon_common/motif.gif") repeat;
    box-shadow: 0px 0px 27px 5px rgba(0,0,0,0.5);

    & .nav-link {
        line-height: 50px;
        font-size: $font-size-h5;
    }
}

.breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
}


footer {
    padding-top: 1.5rem;
    border-top: .05rem solid $gray-lighter;
    margin-top: 1.5rem;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-weight: 700;
}

h1, h2 {
    margin: 20px 0 20px 0;
}

h2 {
    // font-size: 1.5rem;
}

[v-cloak] {
  display: none;
}

.push-top {
    margin-top: 30px;
}

.push-bottom {
    margin-bottom: 30px;
}

.page-title {
    text-align: left;
    margin: 20px 0 50px 0;
}

.breadcrumb {
    padding: 0.75rem 0;
}

.breadcrumb-tools {
    // Button already has a 0.5rem padding
    padding: 0.25rem 0;
}

/* Hack to port BS alpha 5 to alpha 6 */
.col-xs-12 {
    @extend .col-12;
}

.col-xs-11 {
    @extend .col-11;
}

.col-xs-10 {
    @extend .col-10;
}

.col-xs-9 {
    @extend .col-9;
}

.col-xs-8 {
    @extend .col-8;
}

.col-xs-7 {
    @extend .col-7;
}

.col-xs-6 {
    @extend .col-6;
}

.col-xs-5 {
    @extend .col-5;
}

.col-xs-4 {
    @extend .col-4;
}

.col-xs-3 {
    @extend .col-3;
}

.col-xs-2 {
    @extend .col-2;
}

.col-xs-1 {
    @extend .col-1;
}
