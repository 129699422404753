.log-modal-button-wrapper {
    display: inline-block;
}

.log-modal-button {
    font-size: 1.5rem;
    cursor: pointer;
    color: #000000;
    opacity: 0.5;
    transition: opacity 0.2s;

    &[data-position="top-right"] {
        position: absolute;
        top: 5px;
        right: 15px;
    }
}

.log-modal-button:hover {
    opacity: 0.9;
}

.log-modal-entry {
    padding-bottom: 0.5rem;
}

.log-modal-entry__time {
    display: block;
    float: left;
    margin-right: 2rem;

}

.log-modal-entry__body {
    overflow: hidden;
    zoom: 1;
}

.log-modal-entry__msg {

}

.log-modal-entry__plusbtn {
    color: #0275d8;
    cursor: pointer;
    margin-left: 1rem;
}

.log-modal-entry.is-open .fa {
    transform: rotate(90deg);
}

.log-modal-entry__fields {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.2s;
}


.log-modal-entry.is-open .log-modal-entry__fields {
    max-height: 3rem;
}
