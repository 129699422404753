.global-loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;  // see https://getbootstrap.com/docs/4.0/layout/overview/#z-index
    width: 100vw;
    height: 0;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.7);
    opacity: 0;
    transition: opacity 0.2s;
}

.global-loading-indicator--is-visible {
    // Using 'height' instead of 'display' to toggle the visibility
    // because 'display: none' blocks CSS transitions
    height: 100vh;
    opacity: 1;
}

.global-loading-indicator__message {
    font-size: 1.3em;
}
