@font-face {
  font-family: "Trade Gothic";
  font-weight: 700;
  src: url("/static/fonts/dusablon_common/TradeGoticBold.eot?#iefix");
  src: url("/static/fonts/dusablon_common/TradeGoticBold.eot?#iefix") format("eot"), url("/static/fonts/dusablon_common/TradeGoticBold.woff") format("woff"), url("/static/fonts/dusablon_common/TradeGoticBold.ttf") format("truetype"), url("/static/fonts/dusablon_common/TradeGoticBold.svg#d5153ad4-7c93-4757-9147-640e69ec4801") format("svg");
}
@font-face {
  font-family: "Trade Gothic";
  font-weight: 500;
  font-style: italic;
  src: url("/static/fonts/dusablon_common/TradeGotic.eot?#iefix");
  src: url("/static/fonts/dusablon_common/TradeGotic.eot?#iefix") format("eot"), url("/static/fonts/dusablon_common/TradeGotic.woff") format("woff"), url("/static/fonts/dusablon_common/TradeGotic.ttf") format("truetype"), url("/static/fonts/dusablon_common/TradeGotic.svg#09f52831-e1cf-48be-9c1e-2d1577e420b7") format("svg");
}
@font-face {
  font-family: "Trade Gothic";
  font-weight: 500;
  src: url("/static/fonts/dusablon_common/TradeGoticItalic.eot?#iefix");
  src: url("/static/fonts/dusablon_common/TradeGoticItalic.eot?#iefix") format("eot"), url("/static/fonts/dusablon_common/TradeGoticItalic.woff") format("woff"), url("/static/fonts/dusablon_common/TradeGoticItalic.ttf") format("truetype"), url("/static/fonts/dusablon_common/TradeGoticItalic.svg#853e2a9b-4057-42a5-ad7e-0762cda0b13c") format("svg");
}
