.modal-header__close {
    $size: 30px;
    $light_gray: #989898;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -($size/2);
    right: -($size/2);
    width: $size;
    height: $size;
    background-color: #303030;
    color: white;
    border-radius: 50%;
    border: 1px solid $light_gray;
    cursor: pointer;
    font-size: 18px;
}
