@mixin simple-indicator-mixin($size: 30px) {
    display: inline-block;
    width: $size;
    height: $size;
    overflow: hidden;
    font-size: $size * 0.8;
    line-height: $size;
    text-align: center;
    vertical-align: middle;

    .simple-indicator__spinner {
        position: relative;
        top: -($size * 7/30);  // XXX - These ratios are meant to scale from the base size
        left: -($size * 9/30);
        width: $size * 1.5;
        height: auto;
    }

    &.simple-indicator--pull-right {
        position: absolute;
        top: 0;
        right: -$size + 14px;
    }

    &.simple-indicator--pull-left {
        position: absolute;
        top: 0;
        left: -$size + 8px;
    }
}

.simple-indicator {
    @include simple-indicator-mixin();
}

.simple-indicator--small {
    @include simple-indicator-mixin(22px);
}

.simple-indicator--info {
    color: #0275d8;
}

.simple-indicator__icon--is-clickable {
    cursor: pointer;
}
