.light-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;  // see https://getbootstrap.com/docs/4.0/layout/overview/#z-index
    width: 100vw;
    height: 0;
    overflow: hidden;
    background: rgba(black, 0.5);
    opacity: 0;
    transition: opacity 0.2s;
}

.light-box--is-visible {
    // Using 'height' instead of 'display' to toggle the visibility
    // because 'display: none' blocks CSS transitions
    height: 100vh;
    opacity: 1;
}

.light-box__content {
    position: relative;
    padding: 30px;
    background-color: white;
    border: 1px solid #dadada;
    box-shadow: 5px 5px 2px rgba(black, 0.2);
}

.light-box__hide {
    $size: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -($size/2);
    right: -($size/2);
    width: $size;
    height: $size;
    background-color: #303030;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
}
