.VueTables {
    & .VueTables__sortable {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    & .VueTables__date-filter {
        cursor: pointer;
        padding: 5px;

        &:hover {
            text-decoration: underline;
        }

        &:before {
            font-family: FontAwesome;
            content: "\f073";
            display: inline-block;
            padding-right: 3px;
        }
    }

    & .VueTables__limit label {
        display: inline-block;
        margin-right: 5px;
    }

    & .pagination li {
        @extend .page-item;
    }

    & .pagination li a {
        @extend .page-link;
    }

    // Hide the useless and not working <<
    & .pagination li:first-child {
        //display: none;
    }

    // Hide the useless and not working >>
    & .pagination li:last-child {
        //display: none;
    }

    & .list-cell-items {
        padding-left: 15px;
    }

    .VuePagination {
        .VuePagination__count {
            text-align: left !important;
            padding: 0 !important;
            padding-left: 15px !important;
        }
    }

    .sales-notes-column {
        max-width: 250px;
        width: 250px;
        &>div {
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

#action-selector {
    position: absolute;
    z-index: 1000;
}
