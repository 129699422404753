.global-progress-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;  // see https://getbootstrap.com/docs/4.0/layout/overview/#z-index
    width: 100vw;
    height: 0;
    overflow: hidden;
    background-color: rgba(black, 0.5);
    opacity: 0;
    transition: opacity 0.2s;
}

.global-progress-indicator--is-visible {
    // Using 'height' instead of 'display' to toggle the visibility
    // because 'display: none' blocks CSS transitions
    height: 100vh;
    opacity: 1;
}

.global-progress-indicator__container {
    position: relative;
    min-width: 520px;
    padding: 60px;
    background-color: white;
    border: 1px solid #dadada;
    box-shadow: 5px 5px 2px rgba(black, 0.2);
    text-align: center;
}

.global-progress-indicator__message {
    font-size: 1.3em;
}
