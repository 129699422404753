.btn .fa {
    margin-right: 5px;
}

.btn.btn-primary, .btn.btn-secondary,
.btn.btn-warning, .btn.btn-danger,
.btn.btn-info, .btn.btn-success,  {
    text-transform: uppercase;
}

.btn.btn-primary, .btn.btn-warning,
.btn.btn-danger, .btn.btn-info {
    &:focus, &.focus {
        color: white;
    }
}

.table-button {
    padding: 0;
    border: 0;
}
