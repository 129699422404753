#dropdownMenuButton {
    padding-right: 0;
}

nav.navbar.has-environment-warning {
    padding: 25px 0 5px 0;
}

.environment-warning {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2px;
    background-color: $brand-red-color;
    color:  white;
    text-align:  center;

    span {
        display: inline-block;
    }
}

.environment-warning--local {
    $dark_blue: #004766;
    background-color: $dark_blue;
}

.dusablon_version {
    position: absolute;
    top: 2px;
    right: 4px;
    padding: 2px;
    color:  white;
    opacity: 0.8;
    text-align:  center;
    z-index: 9999;
    font-size: 0.75rem;
}