// Revert the normalize.css reset. We want beautiful form fonts.
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
}

:-moz-ui-invalid {
    box-shadow: none;
}

input.locked {
    background-image: url("/static/img/dusablon_common/lock.svg");
    background-size: 1.25rem 1.25rem;
    background-repeat: no-repeat;
    background-position: center right .625rem;
}

select.locked {
    background-image: url("/static/img/dusablon_common/lock.svg");
    background-size: 1.25rem 1.25rem;
    background-repeat: no-repeat;
    background-position: center right 1rem;
}

input.field-datepicker {
    background-image: url("/static/img/dusablon_common/calendar.svg");
    background-size: 1.25rem 1.25rem;
    background-repeat: no-repeat;
    background-position: center right .625rem;
}

input.field-timepicker {
    background-image: url("/static/img/dusablon_common/clock.svg");
    background-size: 1.25rem 1.25rem;
    background-repeat: no-repeat;
    background-position: center right .625rem;
}

hr.separator {
    margin: 30px 0;
    border: 0;
}

.form-check.form-check-lg {
    font-size: 110%;
}

.btn-secondary.is-active {
    @extend .btn-secondary:hover;
}

.btn-group-group {

    display: inline-block;

    &.has-danger {
        border-radius: $border-radius;
        border: $input-btn-border-width solid $brand-danger;
    }

    .btn-group {
        display: block;
        margin-bottom: 10px;
    }

    .btn-group:last-child {
        margin-bottom: 0;
    }
}

.btn-group.has-danger {
    border-radius: $border-radius;
    border: $input-btn-border-width solid $brand-danger;
}

.form-fieldset {
    border: 1px solid #c0c0c0;
    padding: 1em;
    padding-top: 1.4em;

    legend {
        width: auto;
        font-size: 1.2rem;
    }

    .form-group.row:last-child {
        margin-bottom: 0;
    }
}

.form-label, .form-value {
    min-height: 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .form-label-mixed & {
        min-height: unset;
    }
}

.form-value{
    justify-content: unset;
}
.form-value.form-check > label {
    font-weight: normal;
    justify-content: unset;
}

.form-label-star {
    margin-right: .25rem;
}

.has-danger .form-label {
    color: $brand-danger;
}

.has-success .form-label {
    color: $brand-success;
}

.has-warning .form-label {
    color: $brand-warning;
}

// Align the old-style form component's required indicator (*) with the
// new-style ones
.col-form-label > .text-danger {
    display: inline-block;
    padding-right: 3px;
}

// Form inputs that look like regular <p> (mostly useful for uniform vertical
// alignment with form labels).
.form-control--text-display {
    border: 0 !important;
    background-color: transparent !important;
}

.form-decimal-input {
    width: 100%;
}

// XXX - This could become the new default for Dusablon forms
.form--high-contrast {
    .form-fieldset {
        position: relative;
        margin-top: 35px;
        margin-bottom: 55px;
        background-color: #eceeef;

        legend {
            position: absolute;
            top: -35px;
            left: 10px;
            font-size: 1.5em;
        }
    }

    // A fieldset without any border or background. Useful to get uniform
    // field alignments between various form rows, with and without visible
    // fieldsets.
    .form-fieldset--no-box {
        border: none;
        background-color: transparent;
    }

    .form-label,
    .form-check-label {
        font-size: 1.05em;
        font-weight: bold;
    }

    .form-control {
        font-size: 1em;
    }

    &.form--show-invalid-fields {
        button,
        input,
        optgroup,
        select,
        textarea {
            // Fields marked as invalid after HTML form validation
            &:invalid {
                border: 1px solid $brand-danger;

                & + .multiselect .multiselect__tags {
                    border-color: $brand-danger;
                }
            }
        }
    }
}

.form-field-note {
    $gray: #808080;

    margin-top: 7px;
    margin-bottom: 4px;
    font-style: italic;
    font-size: 0.9em;
    line-height: 1.2em;
    color: $gray;
}



.VueTables__filters-row {

    .multiselect {
        min-width: 125px;

        &__option {
            font-size: 0.95rem;
            line-height: 1.25;
            font-weight: normal;

            &--highlight {
                background: #eceeef;
                color: #464a4c;
            }
        }

        &__tags {
            border-radius: 0.25rem;
            min-height: calc(2.25rem + 2px);
            line-height: 1.25;
            color: #464a4c;
            vertical-align: middle;
            padding: 0.375rem 1.75rem 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: normal;
            padding: 0.5rem 1.2rem 0.5rem 0.75rem;
        }

        &__tag {
            font-size: 0.9rem;
            line-height: 1.25;
            background: #eceeef;
            color: #464a4c;
            // &-icon {
            //     line-height: 1.25;
            // }
        }

        &__placeholder {
            color: #464a4c;
            margin-bottom: 0;
        }
        &__select {
            padding: 4px 0px;
            width: 22px;

            &:before {
                color: #464a4c;
                border-color: #464a4c transparent transparent;
            }
        }
    }
}

.import_error_types-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        // background-color: #aeb0b0;
        // border: 1px solid grey;
        // color: white;
        font-size: 0.9rem;
        border-radius: 20px;
        margin-right: 4px;
        padding: 1px 6px;
        display: flex;
        align-items: center;

        &.-alert {
            color: red;
        }
        &.-warning {
            color: orange;
        }
        &.-success {
            color: green;
            justify-content: center;
            font-size: 1rem;
        }
    }
}


.text-input-autocomplete__wrapper {
    position: absolute;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 1;
    // max-height: 150px;
    // overflow-y: auto;
    // top: 0;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        li {
            padding: 6px 12px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.02);
            }
        }
    }
}