$custom_badge_size: 16px;
$custom_badge_danger_color: #c10200;

.custom-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $custom_badge_size;
    height: $custom_badge_size;
    margin: 0;
    padding: 0;
    line-height: 1.1em;
    font-size: 75%;
    border-radius: 50%;
    text-align: center;
    vertical-align: center;
    background-color: black;
    color: white
}

.custom-badge--danger {
    background-color: $custom_badge_danger_color;
}
.custom-badge--primary {
    background-color: blue;
}

// A button with an Apple Mail style indicator in the top-left corner
.btn--with-custom-badge {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .custom-badge {
        position: absolute;
        top: -($custom_badge_size / 2);
        left: -($custom_badge_size / 2);
    }
}
