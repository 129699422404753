.position-relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer !important;
}

.flex-shrink {
    flex-grow: 0;
    flex-shrink: 1;
}

.nowrap,
th.th-nowrap {
    flex-wrap: nowrap;
    white-space: nowrap;
}

.full-width {
    width: 100%;
}
.flex-grow-0 {
    flex-grow: 0;
}

.is-hidden {
    display: none !important;
}
.is-invisible {
    visibility: hidden !important;
}

.is-very-invisible {
    visibility: hidden !important;
    max-width: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
    display: inline-block !important;
    line-height: 0 !important;
    padding-block: 0 !important;
    padding-inline: 0 !important;
    border: 0 !important;
    position: absolute !important;
}